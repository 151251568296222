import PropTypes from 'prop-types';

// Captcha
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

function ReCaptcha({ children, language }) {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={`${process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}`}
      scriptProps={{ async: true, defer: true, appendTo: 'body' }}
      language={language}
    >
      {children}
    </GoogleReCaptchaProvider>
  );
}

ReCaptcha.propTypes = { children: PropTypes.node, language: PropTypes.string };

export default ReCaptcha;
