export function disableScrolling() {
  const x = window.scrollX;
  const y = window.scrollY;
  window.onscroll = function () {
    window.scrollTo(x, y);
  };
  document.body.classList.add('portal-prevent-mobile-scroll');
}

export function enableScrolling() {
  window.onscroll = function () {};
  document.body.classList.remove('portal-prevent-mobile-scroll');
}

export function scrollToElement(element, viewPortWidth = window.innerWidth) {
  if (element && viewPortWidth) {
    const offset = viewPortWidth > 1439 ? 130 : 90;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
}
