export default {
  cards: [],
  selectedCard: {},
  balance: 0,
  awards: {
    byot: [],
    pfc: [],
  },
  periods: [],
  selectedPeriod: {},
  awardProduct: {},
  orderNumber: 0,
};
