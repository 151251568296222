export default {
  offersList: [],
  isLoading: true,
  offersCategories: [],
  limitedTimeOffersList: [],
  offersForYouList: [],
  moreOffersList: [],
  moreWaysToEarn: [],
  featuredOffer: [],
  selectedFilters: '',
  spotlights: [],
  sortBy: {},
  filter: {},
};
