import PropTypes from 'prop-types';
import Msal from './Msal';
import ReactQuery from './ReactQuery';
import ReCaptcha from './ReCaptcha';
import StateMachine from './StateMachine';
import Theme from './Theme';

function Providers({ children, languageCode, store }) {
  return (
    <StateMachine store={store}>
      <ReactQuery>
        <ReCaptcha language={languageCode}>
          <Msal>
            <Theme>{children}</Theme>
          </Msal>
        </ReCaptcha>
      </ReactQuery>
    </StateMachine>
  );
}

export default Providers;

Providers.propTypes = { children: PropTypes.node };
