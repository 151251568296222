export const theme = {
  // Colors
  errorBgColor: '#FFE8E9',
  lightRedColor: 'rgba(236, 17, 26, 0.05)',
  brandBlack: '#333333',
  lightGrayColor: '#F6F7FC',
  lightGrayColor2: '#E2E8EE',
  lightGrayColor3: '#D6D6D6',
  lightGrayColor4: '#757575',
  lightGrayColor5: '#f6f6f6',

  whiteColor: '#ffffff',
  leafColor: '#8EE2AB',
  paleGreenColor: '#ECF5F3',

  // UI COLORS
  uiBlack: '#000000',
  uiCharcoal: '#292929',
  uiWhite: '#FFFFFF',
  uiPurple: '#9B43FF',
  uiDarkPurple: '#6400D6',
  uiGrey01: '#F9F9F9',
  uiGrey02: '#EEEDED',
  uiGrey03: '#949494',
  uiGrey04: '#858585',
  uiDarkGrey: '#757575',
  uiLightPink: '#FEECED',
  uiErrorRed: '#BE061B',
  uiWarningOrange: '#FB6330',
  uiLightGreen: '#ECF5F3',
  uiSuccessGreen: '#138468',
  uiLightOrange: '#FFF3EF',
  uiLightBlue: '#EFF5FF',
  bottomBorderLightGrayOpaque: 'rgba(156, 171, 194, 0.5)',
  uiButtonRed: '#ED0722',
  uiButtonRedBorder: 'rgba(255, 255, 255, 0.75)',
  // BRAND ACCENTS
  uigreen: '#23B574',
  uiRed: '#FF3B48',
  uiPink: '#FF93AA',
  uiBlue: '#2C76EC',
  uiOrange: '#FF6A39',
  uiYellow: '#FFBB01',

  // Fonts
  primaryFont: "'Poppins', 'Arial', Helvetica, sans-serif",

  // TEXT SIZES
  headingLarge: '3rem',
  headingMedium: '2rem',
  headingMedSmall: '1.75rem',
  headingSmall: '1.5rem',
  headingExtraSmall: '1.313rem',
  introduction: '1.25rem',
  fontBody1: '1.125rem',
  fontBody2: '1rem',
  fontBody3: '0.875rem',
  fontLegal: '0.75rem',

  // LINE HEIGHTS
  lineHeight1: 1.125,
  lineHeight2: 1.25,
  lineHeight3: 1.5,
  lineHeight4: 2.5,
  lineHeight5: 1.0,
  lineHeight143: 1.43,

  // Other utils
  borderRadius: '4px',
  borderColor: '#949494',
  shadow: '0px 2px 10px rgba(0, 34, 91, 0.11)',

  // header heights
  headerDesktop: '7.5rem',
  headerMobile: '4.5rem',
  inlineMsgDesktop: '6.25rem',
  inlineMsgMobile: '9rem',
  paddingInlineMsgDesktop: '13.75rem',
  paddingInlineMsgMobile: '13.5rem',
};

export function calculate_age(dateString) {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function subtractYears(numOfYears, date = new Date()) {
  date.setFullYear(date.getFullYear() - numOfYears);

  return date.getFullYear();
}

export function normalizeInput(value, previousValue) {
  // return nothing if no value
  if (!value) return value;

  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;

    // returns: "xxx", "xxx x", "xxx xx", "xxx xxx",
    if (cvLength < 7) return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;

    return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  }
}

export function postalSpace(value, previousValue) {
  // return nothing if no value
  if (!value) return value;

  const currentValue = value.replace(/\W+/g, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;

    if (cvLength < 6) return `${currentValue.slice(0, 3)} ${currentValue.slice(3)}`;

    return `${currentValue.slice(0, 3)} ${currentValue.slice(3, 6)}`;
  }
}

export function convertToSlug(Text) {
  return Text.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
}

export const groupBy = (array, key) =>
  array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);

    return result;
  }, {});

// Sorting Helpers
export function addSortingIndexToObjList(list) {
  if (list?.length) {
    return list.map((item, idx) => ({ ...item, sequence: idx }));
  }

  return list;
}

export function sortByKey(list = [], sortKey = '') {
  if (!sortKey || typeof sortKey !== 'string' || !list.length) {
    console.error('Sort Key is required or List cannot be empty ');
    return [];
  }

  function stringCompare(a, b) {
    const first = a[sortKey].toLowerCase();
    const second = b[sortKey].toLowerCase();
    return first.localeCompare(second);
  }

  function numberCompare(a, b) {
    const first = a[sortKey];
    const second = b[sortKey];
    return first - second;
  }

  const valueType = typeof list[0][sortKey];

  if (valueType === 'string') {
    return list.sort(stringCompare);
  }

  if (valueType === 'number') {
    return list.sort(numberCompare);
  }

  return list;
}

export function isInvalidAplhaSortOptionsObj(options = {}) {
  switch (options) {
    case !options.sortKey:
      console.error('Sort Key is required');
      return true;

    case typeof options.sortKey !== 'string':
      console.error('Sort Key must be of type String');
      return true;
    default:
      break;
  }
}

export const sortObjectArrayAlphabetically = (objArray = [], options = { startWithIdx: null, sortKey: null }) => {
  const { startWithIdx, sortKey } = options;
  const list = [...objArray];
  if (isInvalidAplhaSortOptionsObj(options) || !list.length) {
    return list;
  }

  if (startWithIdx === null) {
    return sortByKey(list, sortKey);
  }

  if (typeof startWithIdx === 'number' && list[startWithIdx]) {
    const [firstItem] = list.splice(startWithIdx, 1);
    return [firstItem, ...sortByKey(list, sortKey)];
  }

  return sortByKey(list, sortKey);
};

export function convertFiltersArrayToObj(arr, keyField) {
  // take an array of filters and generate an object with keys based off a chosen field
  // ex: keyField --> "code" ===> { FEATURED: { selected: false, code: "FEATURED" }, MOVIES: { selected: false, code: "MOVIES" } ... etc }
  return arr.reduce((obj, item) => {
    obj[item[keyField]] = { selected: false, [keyField]: item[keyField] };
    return obj;
  }, {});
}

export function getUniqueListBy(arr, key) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}

export function selectImageFrom({ imagesArray = [], type = 'thumbnail' }) {
  if (imagesArray.length) {
    return imagesArray.find((image) => image.type === type);
  }
}

// --- Currency Helpers ---

export function formatToCurrency(amount, languageCode) {
  return new Intl.NumberFormat(languageCode, {
    style: 'currency',
    currency: 'CAD',

    // These options are needed to round to whole numbers
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
}

export function formatToWholeNumberCurrency(amount, languageCode) {
  let formattedAmount = amount;
  // amount in PointsRadioList is string
  if (typeof amount === 'string') {
    const splitArray = amount.split(',');
    if (languageCode.toLowerCase() === 'fr-ca' && splitArray.length > 1) {
      formattedAmount = splitArray[0];
    }
  }

  return new Intl.NumberFormat(languageCode, {
    style: 'currency',
    currency: 'CAD',

    // These options are needed to round to whole numbers
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(formattedAmount);
}

// Object Helpers
export const isEmpty = (obj = {}) => {
  if (obj === null) return true;

  return Object.keys(obj).length === 0;
};

// Array Helpers

export function chunkArr(arr, batchSize) {
  return arr.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / batchSize);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
}

export function lowerCaseArrayValues(arr) {
  return arr.join(',').toLowerCase().split(',');
}

export function isInArray(arr = [], checkValue = '') {
  return lowerCaseArrayValues(arr).includes(checkValue);
}

export function checkArrayForItems(array) {
  return array && array.length > 0;
}

export function removeDuplicates(data, key) {
  return [...new Map(data.map((item) => [key(item), item])).values()];
}

export function removeEmptyValues(array = []) {
  if (!Array.isArray(array) || !array?.length) return [];

  return array.filter((item) => item);
}

export function arrayEquals(a, b) {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
}

export function deriveListByProvince(list, code) {
  if (!code) return [];
  return list.filter((item) => item.provinces && item.provinces.indexOf(code) >= 0);
}

// Accessibilty
export function handleKeyDown(e, cb) {
  if (e.keyCode === 13) {
    cb(e);
  }
}

export function deriveMetaImageByLocale(languageCode) {
  return languageCode === 'fr-ca'
    ? 'https://cdn-ca.aglty.io/project-harmony/meta/scene-fr-ca.jpg'
    : 'https://cdn-ca.aglty.io/project-harmony/meta/scene-en-ca.jpg';
}

export function getExpandedFirstItem(firstID, id) {
  let retValue = [];
  if (firstID === id) {
    retValue = [id];
  }
  return retValue;
}

export function urlEncodeString(value = '', count = null) {
  let currentCount;
  let decoded;
  const maxDepth = parseInt(process.env.NEXT_PUBLIC_MAX_DECODE_RETRY_COUNT) || 5;
  // eslint-disable-next-line no-console

  try {
    if (!value) return console.error('A value must be provided for url encoding');
    if (!value.includes('%')) return encodeURIComponent(value);

    if (count === null) {
      currentCount = 0;
    } else {
      currentCount = count;
    }

    decoded = window.decodeURIComponent(value);

    if (decoded.includes('%') && currentCount <= maxDepth) {
      return urlEncodeString(decoded, currentCount + 1);
    }

    return value;
  } catch (error) {
    console.error(error);
    return value;
  }
}
