import PropTypes from 'prop-types';

// MSAL
import { MsalProvider } from '@azure/msal-react';
import msalInstance from './msal-instance';

function Msal({ children }) {
  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
}

Msal.propTypes = { children: PropTypes.node };

export default Msal;
