import { PublicClientApplication, EventType } from '@azure/msal-browser';

// B2C
import { selectAccount, redirectIfError } from '../../b2c/b2cFunctions';
import { msalConfig } from '../../b2c/authConfig';

const msalInstance = new PublicClientApplication(msalConfig);

const currentAccounts = msalInstance.getAllAccounts();

// in case of page refresh
selectAccount(msalInstance, currentAccounts);

if (currentAccounts.length > 0) {
  msalInstance.setActiveAccount(currentAccounts[0]);
}

// force logout in certain msal interaction steps
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const result = event.payload;
    const { account } = result;

    // TODO: Revisit this mechanism as we need to properly handle the policy changes depending ont he claim.
    // may need to audi tthe whole auth scheme to handleRedirectPromise
    // For now we just sign the person out after they have made a password change.
    if (account && account.idTokenClaims.acr === 'b2c_1a_passwordchange') {
      msalInstance.logoutRedirect();
    }

    msalInstance.setActiveAccount(account);
  } else if (event.eventType === EventType.LOGIN_FAILURE) {
    redirectIfError(msalInstance, event);
  }
});

export default msalInstance;
