export default {
  sceneNumber: null,
  step: 1,
  guestEmail: '',
  customer: {
    data: null,
    accounts: null,
  },
  preferences: [],
  gender: '',
  onBoardMessageActive: false,
  barcodeImageBase64: '',
  guestPreferences: [],
  shippingAddresses: {},
  selectedShippingAddress: {},
  req: {},
  isGhostKitActivationEnabled: false,
};
