export default {
  giftCardsList: [],
  isLoading: true,
  giftcardCategories: [],
  selectedFilters: {},
  selectedGiftCard: {},
  selectedGiftCardIndex: null,
  redeem: {
    step: 1,
    giftCardDetails: {},
    formats: ['E-Card', 'Physical'], // TODO: use the agility response
    selectedFormat: '',
    quantity: 1,
  },
};
