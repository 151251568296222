import { msalConfig, b2cPolicies, loginRequest } from './authConfig';

let accountId = '';

export function handleResponse(resp, instance) {
  if (resp !== null) {
    accountId = resp.account.homeAccountId;
  } else {
    const currentAccounts = instance.getAllAccounts();
    if (currentAccounts.length < 1) {
      // No cached accounts
    } else if (currentAccounts.length > 1) {
      // Multiple account scenario
      // Add account selection code here
    } else if (currentAccounts.length === 1) {
      accountId = currentAccounts[0].accountId;
    }
  }
}

export function selectAccount(instance, currentAccounts) {
  if (currentAccounts && currentAccounts.length === 1) {
    accountId = currentAccounts[0].homeAccountId;
  } else if (currentAccounts && currentAccounts.length > 1) {
    // Add your account choosing logic here
    console.log('Multiple accounts detected.');
  } else {
    // console.log('User not logged');
  }
}

function handlePolicyChange(response, lang) {
  if (response.idTokenClaims.acr === b2cPolicies.names.emailChange) {
    console.log('Email has been updated.');
    window.location.href = `/${lang}/profile-settings`;
  } else if (response.idTokenClaims.acr === b2cPolicies.names.phoneChange) {
    console.log('Phone has been updated.');
    window.location.href = `/${lang}/profile-settings`;
  } else if (response.idTokenClaims.acr === b2cPolicies.names.passwordChange) {
    console.log('Password has been updated.');
    window.location.href = `/${lang}/profile-settings`;
  } else if (response.idTokenClaims.acr === b2cPolicies.names.forgotPassword) {
    console.log('Password has been reset successfully. \nPlease sign-in with your new password.');
    instance.logout();
    window.location.href = `/${lang}/signin`;
  } else if (response.idTokenClaims.acr === b2cPolicies.names.signIn) {
    console.log('Sign in');
    window.location.href = `/${lang}/`;
  }
}

export function b2cChangeEmail(instance, lang) {
  const extraQueryParameters = {
    ui_locales: lang,
  };
  const redirectUri = `${`${process.env.NEXT_PUBLIC_REDIRECT_URI}` + '/'}${lang}`;
  const b2cChangeEmailRequest = {
    scopes: loginRequest.scopes,
    extraQueryParameters,
    ...b2cPolicies.authorities.emailChange,
    redirectUri,
  };
  instance
    .loginRedirect(b2cChangeEmailRequest)
    .then((response) => handlePolicyChange(response, lang))
    .catch((error) => {
      console.log(error);
    });
}

export function b2cChangePassword(instance, lang) {
  sessionStorage.setItem('interaction_in_progress', true);
  const extraQueryParameters = {
    ui_locales: lang,
  };
  const redirectUri = `${`${process.env.NEXT_PUBLIC_REDIRECT_URI}` + '/'}${lang}`;
  const b2cChangePasswordRequest = {
    scopes: loginRequest.scopes,
    extraQueryParameters,
    ...b2cPolicies.authorities.passwordChange,
    redirectUri,
  };

  instance
    .loginRedirect(b2cChangePasswordRequest)
    .then((response) => handlePolicyChange(response, lang))
    .catch((error) => {
      console.log(error);
    });
}

export function b2cResetPassword(instance, lang) {
  sessionStorage.setItem('interaction_in_progress', true);
  const extraQueryParameters = {
    ui_locales: lang,
  };
  const redirectUri = `${`${process.env.NEXT_PUBLIC_REDIRECT_URI}` + '/'}${lang}`;
  const b2cResetPasswordRequest = {
    // scopes: loginRequest.scopes, TODO: remove not needed?
    extraQueryParameters,
    ...b2cPolicies.authorities.resetPassword,
    redirectUri,
  };

  instance.loginRedirect(b2cResetPasswordRequest);
}

export function b2cChangePhone(instance, lang) {
  const extraQueryParameters = {
    ui_locales: lang,
  };
  const redirectUri = `${`${process.env.NEXT_PUBLIC_REDIRECT_URI}` + '/'}${lang}`;
  const b2cPhoneChangeRequest = {
    scopes: loginRequest.scopes,
    extraQueryParameters,
    ...b2cPolicies.authorities.phoneChange,
    redirectUri,
  };
  instance
    .loginRedirect(b2cPhoneChangeRequest)
    .then((response) => handlePolicyChange(response, lang))
    .catch((error) => {
      console.log(error);
    });
}

export async function b2cSso(instance, lang, redirectUri, domainHint) {
  const ssoLoginRequest = {
    scopes: loginRequest.scopes,
    redirectUri: `${redirectUri}/${lang}`,
    domainHint,
    extraQueryParameters: {
      ui_locales: lang,
    },
  };
  await instance.loginRedirect(ssoLoginRequest);
  // }
}

export async function ssoLogout(instance) {
  const currAccount = instance.getAccountByHomeId(accountId);
  const redirectUrl = window.location.href.replace('singlesignon', 'ssoloading');
  if (currAccount) {
    const logoutRequest = {
      account: currAccount,
      postLogoutRedirectUri: redirectUrl,
    };
    await instance.logoutRedirect(logoutRequest).catch((e) => {
      // console.log('Pre SSO Logout Error: ' + e);
    });
  } else {
    window.location.replace(redirectUrl);
  }
}

export function b2cSignIn(instance, lang) {
  const queryParams = (loginRequest.extraQueryParameters = {
    ui_locales: lang,
  });
  loginRequest.redirectUri = `${msalConfig.auth.redirectUri}/${lang}`;
  instance
    .loginRedirect(loginRequest)
    .then(handleResponse)
    .catch((error) => {
      console.log(error);

      // Error handling
      if (error.errorMessage) {
        if (error.errorMessage.indexOf('AADB2C90118') > -1) {
          instance.authority = b2cPolicies.authorities.forgotPassword;
          instance
            .loginRedirect(instance.authority, queryParams)
            .then((response) => handlePolicyChange(response, lang))
            .catch((error) => {
              console.log(error);
            });
        }
      }
    });
}

export function b2cSignInWithRedirectUrl(instance, lang, redirectUrl) {
  const queryParams = (loginRequest.extraQueryParameters = {
    ui_locales: lang,
  });

  loginRequest.redirectUri = `${redirectUrl}/${lang}`;

  instance
    .loginRedirect(loginRequest)
    .then(handleResponse)
    .catch((error) => {
      console.log(error);

      // Error handling
      if (error.errorMessage) {
        if (error.errorMessage.indexOf('AADB2C90118') > -1) {
          instance.authority = b2cPolicies.authorities.forgotPassword;
          instance
            .loginRedirect(instance.authority, queryParams)
            .then((response) => handlePolicyChange(response, lang))
            .catch((error) => {
              console.log(error);
            });
        }
      }
    });
}

export function b2cSignInPromptLogin(instance, lang, redirectUrl) {
  const prompLoginRequest = {
    scopes: ['openid'],
    prompt: 'login',
    redirectUri: redirectUrl,
  };
  instance.authority = b2cPolicies.authorities.signIn;
  instance
    .loginRedirect(prompLoginRequest)
    .then((response) => {
      console.info('LoginRedirect Prompt Login Response: ', response);
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function logout(instance, redirectUrl) {
  // Choose which account to logout from.
  const logoutRequest = {
    account: instance.getAccountByHomeId(accountId),
    postLogoutRedirectUri: redirectUrl,
  };

  try {
    const res = await instance.logoutRedirect();
    if (res) {
      console.log(res);
    }
    localStorage.setItem('ssoLogoutCompleted', true);
  } catch (error) {
    console.log(error);
    localStorage.setItem('ssoLogoutFailed', true);
  }
}

export async function b2cSignOut(instance, lang, cb) {
  // Choose which account to logout from.
  const logoutRequest = {
    account: instance.getAccountByHomeId(accountId),
    postLogoutRedirectUri: `${msalConfig.auth.redirectUri}/${lang}`,
    extraQueryParameters: { ui_locales: lang },
  };
  try {
    const res = await instance.logoutRedirect(logoutRequest);
    await handleResponse(res);
    if (typeof cb === 'function') {
      cb();
    }
  } catch (error) {
    console.log(error);
  }
}

export function redirectIfError(instance, event) {
  const isFrench = window.location.href.indexOf('/fr-ca') > 1;
  const selectedLang = isFrench ? 'fr-ca' : 'en-ca';
  if (event.error.errorMessage && event.error.errorMessage.indexOf('AADB2C90118') > -1) {
    const extraQueryParameters = {
      ui_locales: selectedLang,
    };
    const redirectUri = `${`${process.env.NEXT_PUBLIC_REDIRECT_URI}` + '/'}${selectedLang}`;
    const b2cPasswordForgotRequest = {
      scopes: loginRequest.scopes,
      extraQueryParameters,
      ...b2cPolicies.authorities.forgotPassword,
      redirectUri,
    };
    instance.loginRedirect(b2cPasswordForgotRequest).then((response) => handlePolicyChange(response));
  } else if (event.error.errorMessage && event.error.errorMessage.indexOf('AADB2C90091') > -1) {
    const extraQueryParameters = {
      ui_locales: selectedLang,
    };
    const redirectUri = `${`${process.env.NEXT_PUBLIC_REDIRECT_URI}` + '/'}${selectedLang}`;
    const req = {
      scopes: loginRequest.scopes,
      extraQueryParameters,
      ...b2cPolicies.authorities.signIn,
      redirectUri,
    };
    instance
      .loginRedirect(req)
      .then((response) => handlePolicyChange(response))
      .catch((error) => {
        console.log(error);
      });
  } else if (event.error.errorMessage && event.error.errorMessage.indexOf('AADB2C90075') > -1) {
    const extraQueryParameters = {
      ui_locales: selectedLang,
    };
    const redirectUri = `${`${process.env.NEXT_PUBLIC_REDIRECT_URI}` + '/'}${selectedLang}`;
    const req = {
      scopes: loginRequest.scopes,
      extraQueryParameters,
      ...b2cPolicies.authorities.signIn,
      redirectUri,
    };
    instance
      .loginRedirect(req)
      .then((response) => handlePolicyChange(response))
      .catch((error) => {
        console.log(error);
      });
  }
}
