import PropTypes from 'prop-types';

// React Query
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

function ReactQuery({ children }) {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}

ReactQuery.propTypes = { children: PropTypes.node };

export default ReactQuery;
