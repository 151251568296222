// for cypress test - modification for auth token and successful login

export const msalConfigMock = {
  auth: {
    clientId: `${process.env.NEXT_PUBLIC_CLIENT_ID}`,
    authority: `${process.env.NEXT_PUBLIC_AUTHORITY_URL}`,
    knownAuthorities: [`${process.env.NEXT_PUBLIC_KNOWN_AUTHORITY}`],
    redirectUri: `${process.env.NEXT_PUBLIC_REDIRECT_URI}`,
    navigateToLoginRequestUrl: false,
    authorityMetadata: `{"authorization_endpoint":"${process.env.NEXT_PUBLIC_AUTHORITY_URL}/oauth2/v2.0/authorize","token_endpoint":"${process.env.NEXT_PUBLIC_AUTHORITY_URL}/oauth2/v2.0/token","end_session_endpoint":"${process.env.NEXT_PUBLIC_AUTHORITY_URL}/oauth2/v2.0/logout","issuer":"https://${process.env.NEXT_PUBLIC_KNOWN_AUTHORITY}/a1510b16-a733-41dd-a7e2-7b4cc0419c5c/v2.0/"}`,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
    secureCookies: false,
  },
};
