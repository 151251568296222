import { StateMachineProvider, createStore } from 'little-state-machine';
import PropTypes from 'prop-types';

// Store
import {
  transactions,
  points,
  user,
  order,
  giftCards,
  notification,
  offers,
  layout,
  announcement,
  navigation,
  location,
  session,
  language,
  inlineMessage,
} from '../../store/states';

const defaultStore = {
  user,
  points,
  transactions,
  order,
  giftCards,
  notification,
  offers,
  layout,
  announcement,
  navigation,
  session,
  language,
  location,
  inlineMessage,
};

export default function StateMachine({ children, store }) {
  createStore(store || defaultStore);

  return <StateMachineProvider>{children}</StateMachineProvider>;
}

StateMachine.propTypes = { children: PropTypes.node };
