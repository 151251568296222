export default {
  step: 1,
  added: [],
  lastAdded: '',
  selectedFromDate: '',
  selectedToDate: '',
  fromDate: '',
  toDate: '',
  type: ['ALL'],
  category: ['ALL'],
  cardType: ['ALL'],
  history: {
    transactions: {},
    isEmpty: false,
  },
  reset: false,
};
