import { createGlobalStyle } from 'styled-components';

const CustomStyles = createGlobalStyle`

  html {
    color: ${(props) => props.theme.uiCharcoal};
    font-family: ${(props) => props.theme.primaryFont};
  }

  #mainContentPadding {
    
    padding-top: ${(props) => props.theme.headerMobile};
    @media screen and (min-width: 1440px) {
      padding-top:  ${(props) => props.theme.headerDesktop};
    }

    &.inline-message-padding{
      padding-top: ${(props) => props.theme.paddingInlineMsgMobile};
      @media screen and (min-width: 1440px) {
        padding-top:  ${(props) => props.theme.paddingInlineMsgDesktop};
      }
    }
  }
 
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.main-content-blank-template{
  padding-top: ${(props) => props.theme.headerMobile};
}

input[type=number] {
  -moz-appearance: textfield;
}

  ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.uiGrey04};
      border-radius: 10px;
      transition: background 0.2s linear;

      &:hover {
        background: ${(props) => props.theme.brandBlack};
      }
    }
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.lightGrayColor};
  }
  ::-webkit-scrollbar {
    width: 11px;
  }
  ::-webkit-scrollbar {
    height: 8px;
  }
h1{
  line-height: ${(props) => props.theme.lineHeight1};
  font-size: ${(props) => props.theme.headingMedSmall};
  @media screen and (min-width: 641px) {
    font-size: 2.25rem;
  }
  @media screen and (min-width: 768px) {
    font-size: ${(props) => props.theme.headingLarge};
  }
}
h2{
  line-height: ${(props) => props.theme.lineHeight1};

  font-size: ${(props) => props.theme.fontBody1};
  @media screen and (min-width: 641px) {
    font-size: ${(props) => props.theme.headingExtraSmall};
  }
  @media screen and (min-width: 768px) {
    font-size: ${(props) => props.theme.headingExtraSmall};
  }
}
h3{
  font-size: ${(props) => props.theme.fontBody1};
  @media screen and (min-width: 641px) {
    font-size: ${(props) => props.theme.headingExtraSmall};
  }
}
  //HEADLINES overwrite styles
  h1, h2, h3, h4, strong{
    font-weight: bold;
    line-height: ${(props) => props.theme.lineHeight2};

    &.headline-large{
      line-height: ${(props) => props.theme.lineHeight1};
      font-size: ${(props) => props.theme.headingMedSmall};
      @media screen and (min-width: 768px) and (max-width: 1023px){
        font-size: ${(props) => props.theme.headingMedium};
      }
      @media screen and (min-width: 1024px){
        font-size: ${(props) => props.theme.headingLarge};
      }
    }
    &.headline-medium{
      font-size: ${(props) => props.theme.headingExtraSmall};
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        font-size: ${(props) => props.theme.headingSmall};
      }
      @media screen and (min-width: 1024px) {
        font-size: ${(props) => props.theme.headingMedium};
      }
    }
    &.headline-small{
      font-size: ${(props) => props.theme.fontBody1};
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        font-size: ${(props) => props.theme.headingExtraSmall};
      }
      @media screen and (min-width: 1024px) {
        font-size: ${(props) => props.theme.headingSmall};
      }
    }
    &.headline-extra-small{
      font-size: ${(props) => props.theme.fontBody2};
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        font-size: ${(props) => props.theme.fontBody1};
      }
      @media screen and (min-width: 1024px) {
        font-size: ${(props) => props.theme.headingExtraSmall};
      }
    }
    &.headline-extra-small2{
      font-size: ${(props) => props.theme.fontBody3};
      @media screen and (min-width: 768px) {
        font-size: ${(props) => props.theme.fontBody2};
      }

    }
  }
div.title-headline-large{
  h1{
    line-height: ${(props) => props.theme.lineHeight1};
    font-size: ${(props) => props.theme.headingMedSmall};
    @media screen and (min-width: 768px) and (max-width: 1023px){
      font-size: ${(props) => props.theme.headingMedium};
    }
    @media screen and (min-width: 1024px){
      font-size: ${(props) => props.theme.headingLarge};
    }
  }

}
  .inner-header, .inner-header-purple {
    margin: 1.5rem 0;
    font-weight:bold;
    font-size:1rem;
    @media screen and (max-width: 640px) {
      font-size: ${(props) => props.theme.fontBody3};
    }
  }
  .inner-header-purple {
    color: ${(props) => props.theme.uiDarkPurple};
  }

  ul, ol {
    padding-left: 2.25rem;
    margin-bottom: 2rem;
    color: ${(props) => props.theme.uiCharcoal};
    display: list-item;
    list-style: initial;
  }

  ul {
    display: block;
    list-style: disc;
  }

  ol {
    display: block;
    list-style: decimal;
  }

  em {
    font-style:italic;
  }

  p {
    font-size: ${(props) => props.theme.fontBody3};
    color: ${(props) => props.theme.uiCharcoal};
    margin-bottom: 1rem;
    line-height: ${(props) => props.theme.lineHeight2};

    strong {
      font-weight: bold;
    }

    &.sub-title{
      color: ${(props) => props.theme.lightGrayColor4};
      font-size: ${(props) => props.theme.fontBody2};
      margin-bottom: 2rem;
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        font-size: ${(props) => props.theme.fontBody1};
        margin-bottom: 2.5rem;
      }
      @media screen and (min-width: 1024px) {
        font-size: ${(props) => props.theme.headingExtraSmall};
        margin-bottom: 3.75rem;
      }
    }
  }

  a {
    text-decoration: underline;
    transition: all 0.3s ease-in;

    &:focus,
    &:hover {
      text-decoration: none;
    }

   &:focus {
      outline: 2px solid ${(props) => props.theme.uiPurple};
      outline-offset: 2px;
    }
  }

  a.text-button-primary{
    font-size: ${(props) => props.theme.fontBody1};
    line-height: ${(props) => props.theme.lineHeight3};
    font-weight: bold;
    &:active,
    &:hover {
      text-decoration: underline;
      color: ${(props) => props.theme.uiPurple};
    }

    &:focus {
      text-decoration: underline;
      color: ${(props) => props.theme.uiCharcoal};
      outline: 2px solid ${(props) => props.theme.uiPurple};
      outline-offset: 0.5rem;
    }
    @media screen and (min-width: 768px) {
      font-size: ${(props) => props.theme.introduction};
    }
  }

  header {
    a {
      text-decoration: none;
    }
  }

  .grey-color {
    color: ${(props) => props.theme.lightGrayColor4};
  }

  .isGray {
    background-color: ${(props) => props.theme.uiGrey01};
  }

  .isLightBlack {
    background-color: ${(props) => props.theme.uiCharcoal};
  }

  .isWhite {
    background-color: ${(props) => props.theme.uiGrey01};

    @media screen and (min-width: 641px) {
      background-color: ${(props) => props.theme.uiWhite};
    }
  }

  .mobileIsWhite {
    @media screen and (max-width: 640px) {
      background-color: ${(props) => props.theme.uiWhite} !important;
    }
  }
  .isBlack {
    background-color: ${(props) => props.theme.uiBlack};
    color: ${(props) => props.theme.uiWhite};
  }

  .min-half-screen {
    min-height: 50vh;
  }

  .pattern-bg {
    background-color: ${(props) => props.theme.uiWhite};

    @media screen and (min-width: 641px) {
      background-color: ${(props) => props.theme.uiBlack};
      background-image: url('/assets/cross-circles.svg'), url('/assets/cross-circles-small.svg'), url('/assets/cross-circles-small.svg'), url('/assets/cross-circles.svg'), url('/assets/cross-circles-small.svg');
      background-repeat: no-repeat;
      background-position: -20px 50%, 10% 15%, 15% 75%, 102% 15%, 85% 45%;
      background-size: 120px, 30px, 30px;
    }

    @media screen and (min-width: 768px) {
      background-size: 150px, 40px, 40px;
    }

    @media screen and (min-width: 1024px) {
      background-position: -20px 50%, 10% 12%, 15% 85%, 102% 15%, 85% 60%;
      background-size: 220px, 50px, 50px;
    }
  }

  .signin-pattern-bg{
    background-color: ${(props) => props.theme.uiWhite};

    @media screen and (min-width: 641px) {
      background-color: ${(props) => props.theme.uiBlack};
      background-image:
        url('/assets/cross-circles.svg'),
        url('/assets/cross-circles-small.svg'),
        url('/assets/cross-circles.svg'),
        url('/assets/cross-circles-small.svg');
      background-repeat: no-repeat;
      background-position: -20px 85%, 20% 10%, 102% 10%, 80% 75%;
      background-size: 220px, 50px, 220px, 50px;
    }

  }

  sub, sup {
    font-size: 75%!important;
  }

  hr{
    border-top: 1px solid #D6D6D6;
    margin:1rem auto;
  }
  .link-like-button,
  button {
    width: 100%;
    padding: 1rem 2rem;
    background-color: ${(props) => props.theme.uiPurple};
    color: ${(props) => props.theme.whiteColor};
    border-radius: 9999px;
    font-weight: bold;
    letter-spacing: .025em;
    font-size: ${(props) => props.theme.fontBody2};

    &:focus {
      outline: 2px solid ${(props) => props.theme.uiPurple};
      border-radius: 0;
    }
  }
  .link-like-button {
    @media screen and (min-width: 768px) {
      max-width: 50%;
    }
  }

  .form-container {
    h2 {
      text-align: center;
    }
  }

  



  label {
    cursor: pointer;
    color: ${(props) => props.theme.uiCharcoal};
    display: block;
    margin: 0.75rem auto 0.5rem;
    font-size: ${(props) => props.theme.fontBody3};
    line-height: ${(props) => props.theme.lineHeight3};

    &.isDisabled {
      color: ${(props) => props.theme.lightGrayColor3};
    }
  }

  form {
    padding: 5rem;

    input[type=password] {
      height: 50px;
    }
    input[type=text], input[type=tel], input[type=email], input[type=number], input[type=password], select {
      position: relative;
      width: 100%;
      font-size: ${(props) => props.theme.fontBody3};
      line-height: ${(props) => props.theme.lineHeight2};
      background-color: transparent;
      border: 1px solid ${(props) => props.theme.uiGrey04};
      padding: 1rem;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
      outline: none;
      max-height: 45px;

      &:hover {
        border: 1px solid ${(props) => props.theme.uiPurple};
      }

      &:focus {
        outline:2px solid ${(props) => props.theme.uiPurple};
      }

      @media screen and (min-width: 520px) {
        font-size: ${(props) => props.theme.fontBody3} !important;
      }

      ::placeholder {
        --tw-placeholder-opacity: 1;
        color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
      }
    }
    input[type="checkbox"] {
      position: relative;
      cursor: pointer;
      width: 1.5rem;
      min-width: 1.5rem;
      height: 1.5rem;
      border: 1px solid ${(props) => props.theme.uiGrey04};
      padding: 0.5rem;
      margin-right: 0.5rem;
      transition: all 0.2s ease-in;
      outline: none;

      &:hover {
        border: 1px solid ${(props) => props.theme.uiPurple};
      }

      &:focus {
        outline: none;
        border: 1px solid ${(props) => props.theme.uiPurple};
        outline: 3px solid ${(props) => props.theme.uiPurple};
      }

      &.pressed {
        border: 1px solid ${(props) => props.theme.uiCharcoal};
      }
    }
    input[type="radio"] {
      position: relative;
      cursor: pointer;
      padding: 0.5rem;
      margin-top: 0.25rem;
      margin-right: 0.5rem;
      transition: all 0.2s ease-in;
    }
     input[type="checkbox"]:checked {
      background-color: ${(props) => props.theme.uiCharcoal};
      background-image: url(/assets/white-checkmark.svg);
      background-size: 75%;
      background-position: center;
      background-repeat: no-repeat;
    }
    .field-has-error {
      background: rgba(236, 17, 26, 0.05) !important;
      border-color: ${(props) => props.theme.uiErrorRed} !important;
    }
    .button-has-error {
      opacity: 0.5
    }
    input[type="checkbox"] + label{
      display: inline-block;
      cursor: pointer;
    }
  }
  .select-wrapper {
    position: relative;
  }
  select {
    cursor: pointer;
    margin-bottom: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }
  select::-ms-expand {
    display: none;
  }
  .select-styled {
    transition: all 0.2s ease-in;
  }
  .color-black {
    color: #333333 !important;
  }
  .color-red {
    color: rgba(236, 17, 26, 1) !important;
  }
  .lower-zindex {
    z-index: 0 !important;
  }
  .no-wrap {
    white-space: nowrap;
  }
  #create-account,
  #signin,
  #profile-settings {
    background: ${(props) => props.theme.lightGrayColor};
  }
  .slides {
    position: relative;
    .slick-prev, .slick-next {
      position: absolute;
      top: 50%;
      z-index: 1;
    }
    .slick-disabled {
      opacity: 0.5;
    }
  }
  .pfc-select-amount {
    @media screen and (min-width: 768px) {
      padding-left: 1rem;
    }
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }

  .form-select-container {
    > div,
    .select-options {
      font-size: ${(props) => props.theme.fontBody3};
    }
  }

  .cta-arrow-link {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 2.75rem;
    height: 2.75rem;
    background-color: ${(props) => props.theme.uiPurple};
    background-image: url('/assets/arrow-right-white-2.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 12px;
  }

  .spotlight-banner-learnmore-container {
    position: relative;
    display: inline-block;
    width: auto;

    @media screen and (max-width: 767px) {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px 15px;
      font-weight: bold;
    }

    .spotlight-banner-learnmore {
      &:hover {
        &:after {
          width: 0;
        }
      }

      &:after {
        content: '';
        display: inline-block;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        border-bottom: 1px solid ${(props) => props.theme.whiteColor};
        -webkit-transition: width 0.3s;
        transition: width 0.3s;
      }

      @media screen and (max-width: 767px) {
        position: relative;
        font-size: ${(props) => props.theme.fontLegal};
      }
    }
  }

  .onboarding-overlay {
    height: 100vh;
  }

  #portal-home1{
    position: absolute;
    left: 0;
    bottom: 0;
    display:block;
  }
  #portal-mobile-home1{
    display:none;
  }
  @media screen and (max-width: 640px) {
    #portal-home1{
      display:none;
    }
    #portal-mobile-home1{
      display:block;
      position: absolute;
      left: 1.125rem;
      right: 1.125rem;
    }
  }

  #portal-rewards1  {
    position: absolute;
    left: 0;
    display:block;
  }
  #portal-mobile-rewards1{
    display:none;
  }
  @media screen and (max-width: 1439px) {
    #portal-rewards1{
      display:none;
    }
     #portal-mobile-rewards1 {
      position: absolute;
      left: 0;
      right:0;
      display:block;
    }
  }

  #portal-rewards2{
    position: absolute;
    right: 0;
    left: 0;
    display:block;
  }
  #portal-mobile-rewards2{
    display:none;
  }
  @media screen and (max-width: 640px) {
    #portal-rewards2{
      display:none;
    }
    #portal-mobile-rewards2{
      display:block;
      position: absolute;
      left: 1.125rem;
      right: 1.125rem;
    }
  }

  #portal-offers1 {
    position: absolute;
    left: 0;
    display:block;
  }
  #portal-mobile-offers1{
    display:none;
  }
  @media screen and (max-width: 1439px) {
    #portal-offers1{
      display:none;
    }
     #portal-mobile-offers1 {position: absolute;
      left: 0;
      right:0;
      display:block;
    }
  }

  .prevent-mobile-scroll {
    overflow: hidden;
    position: relative;
    height: 100%;

    @media screen and (min-width: 1440px) {
      overflow: auto;
    }
  }

  .portal-prevent-mobile-scroll {
    overflow: hidden;
  }


  /* figma design, cant be implemented, data not consistent
  .juicer-feed.modern li.feed-item{
    display:flex;
    flex-flow: row wrap;
    width: 98%;
    border: 1px solid #D6D6D6;
    border-width:1px 1px 1px 1px !important;
  }
  .j-image{
    order:1;

    width:45%;
  }
   .j-poster{
    order:-1;
    width:100%;
  }
  .j-text{
    order:0;

    width:45%;
  } */

  .juicer-feed.modern li.feed-item{
    border: 1px solid #D6D6D6;
    border-width:1px 1px 1px 1px !important;
  }
  .j-loading {
    border-color: ${(props) => props.theme.uiPurple} !important;
   }

   .j-loading:before {
    background-color: ${(props) => props.theme.uiPurple} !important;
   }

   .page-header-title {
     margin-top: 2rem;
   }


.global-content-wrapper{
  max-width:79.75rem;
  margin-left:auto;
  margin-right:auto;
  width:100%;
  @media screen and (max-width: 640px) {    
    padding-left:1.125rem;
    padding-right:1.125rem;
  }
  @media screen and (min-width: 641px) and (max-width: 1439px){     
    padding-left: 2.125rem;
    padding-right: 2.125rem;
  }
  @media screen and (min-width: 1440px){
    max-width:90rem;
    padding-left: 7.25rem;
    padding-right: 7.25rem;
  }
}

.bottom-shadow{
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
  overflow: overlay;
}
.section-content-wrapper{
  margin-top:7.5rem;
  @media screen and (max-width: 767px) {
    margin-top:3.75rem;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    margin-top:5rem;
  }

  &:last-of-type{
    margin-bottom:7.5rem;
    @media screen and (max-width: 767px) {
      margin-bottom:3.75rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      margin-bottom:5rem;
    }
  }
}

.section-homepage-offers-wrapper{
  margin-top:6.875rem;

  @media screen and (min-width: 768px) and (max-width: 1023px)  {
    margin-top:2.5rem;
  }
  @media screen and (max-width: 767px) {
    margin-top:2rem;
  }
}

.page-bottom-spacing {
  padding-bottom: 120px;
  @media screen and (max-width: 767px){
    padding-bottom: 0px;
  }
}
// add space above footer on pades like  how-it-works
.last-section-bottom-spacing {
  padding-bottom:3.5rem;
    @media screen and (max-width: 767px) {
      padding-bottom:1.75rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      padding-bottom:2.5rem;
    }
}

.reg-gender-field {
  position: relative;
  z-index: 3;
}


#scene-home,
#offers,
#offers--limited-time-offers {
  .stick-head-wrapper {
    z-index: 99999;
  }
}

`;

const GlobalStyles = () => <CustomStyles />;

export default GlobalStyles;
